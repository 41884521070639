<template>
  <div class="confused-container">
    <img src="@/assets/images/building.jpg" class="building" />
    <div class="message">
      <span class="heading">
        <slot name="heading"> Error! </slot>
      </span>
      <p class="error">
        <slot name="text">
          Whoops! Something went wrong Why don't you go
          <router-link :to="{ name: 'home' }">back home</router-link>?
        </slot>
      </p>
    </div>
    <img src="@/assets/images/confused.gif" class="travolta" />
  </div>
</template>

<style lang="scss">
.confused-container {
  height: calc(100vh - 40px);
  overflow: hidden;
  width: 100%;
  display: flex;

  .building {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .travolta {
    display: block;
    width: 300px;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 2;
    margin-bottom: 20px;
  }

  .message {
    display: block;
    min-width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 2px 0 15px -4px rgba(0, 0, 0, 0.5),
      -2px 0 8px -4px rgba(0, 0, 0, 0.5);
    background-color: rgba(255, 255, 255, 0.8);
    border: solid 1px #fefefe;
    text-align: center;
    z-index: 3;
    padding: 0 0.5rem;

    .heading {
      font-size: 3rem;
      letter-spacing: 0.05rem;
      line-height: 4rem;
      font-weight: 700;
    }

    .error {
      font-size: 1rem;
      line-height: 1.25rem;
      margin: 1rem 0;
    }
  }
}
</style>

<script>
export default {
  name: "BaseError",
}
</script>
